<template>
  <div class="app-loader">
    <img src="@/assets/images/soy_logo_svg.svg" />
  </div>
</template>

<script>
export default {
  name: "AppLoader",
};
</script>

<style lang="scss">
.app-loader {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background-color: #12141d;

  img {
    width: 100px;
    height: 100px;
    animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
  }
}

@keyframes pulse {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}
</style>
