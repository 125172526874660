<template>
  <div class="MobileContainer">
    <div class="topBar">
      <div class="upperPart">
        <img class="logoImg" :src="logoSoy" alt="shop on you" />
        <div class="avatar-wrapper">
          <span>{{ yellowUserLetter }}</span>
        </div>
      </div>
      <div class="downPart">
        <div class="onglet">
          <span class="selected">En cours</span>
        </div>
        <div class="onglet" @click="changeOnglet">
          <span>Historique</span>
        </div>
        <div class="onglet" @click="changeOnglet">
          <span>Paramètres</span>
        </div>
      </div>
    </div>
    <div class="midle">
      <v-img
        class="emptyFlowImg"
        :src="emptyFlowImage"
        :lazy-src="emptyFlowImage"
        contain
      />
      <span>Votre Flow est vide ...</span>
    </div>
    <div class="bottomBar">
      <plus-button />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { errorMessage } from "@/core/js/utils/msg";

import PlusButton from "@/views/mobile/PlusButton.vue";

export default {
  name: "MobileBase",

  components: {
    PlusButton,
  },

  computed: {
    ...mapGetters("userStore", ["user"]),
    yellowUserLetter() {
      return this.user && this.user.firstname ? this.user.firstname[0] : null;
    },
    logoSoy() {
      return require("@/assets/images/logo.svg");
    },
    emptyFlowImage() {
      return require("@/assets/images/nap.svg");
    },
  },

  methods: {
    changeOnglet() {
      errorMessage("Fonctionnalitée pas encore disponible !");
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/scss/global";

.MobileContainer {
  background-color: $bg-color;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .topBar {
    background-color: white;
    box-shadow: 0 0 15px 0 rgb(50 117 208 / 10%);
    display: flex;
    flex-direction: column;
    margin-bottom: auto;
    margin-top: 0;
    height: 14vh;
    width: 100%;
    .upperPart {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 1rem;
      height: 60%;
      .logoImg {
        width: 40%;
        max-width: 175px;
      }
      .avatar-wrapper {
        height: 2.25rem;
        width: 2.25rem;
        background-color: $slate;
        color: white;
        border-radius: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        span {
          font-size: 2em;
        }
      }
    }
    .downPart {
      width: 100%;
      display: flex;
      height: 40%;
      .onglet {
        cursor: pointer;
        width: calc(100% / 3);
        display: flex;
        align-items: center;
        justify-content: center;
        color: $slate;
        font-size: 1rem;
        .selected {
          color: $primary;
          font-weight: bold;
          &::after {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            background: $primary;
            height: 5px;
            border-radius: 50px;
            margin-bottom: -0.1rem;
          }
        }
        span {
          display: flex;
          position: relative;
          height: 100%;
          align-items: center;
        }
      }
    }
  }
  .midle {
    margin: auto;
    text-align: center;
    .emptyFlowImg {
      width: 7rem;
      margin: auto;
      margin-bottom: 1.5rem;
    }
    span {
      font-size: 1.25rem;
      color: $primary;
      font-weight: bold;
    }
    p {
      margin-top: 1rem;
      font-size: 1rem;
      font-weight: bold;
    }
  }
  .bottomBar {
    box-shadow: 0 0 15px 0 rgb(50 117 208 / 10%);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 0;
    margin-top: auto;
    height: 7vh;
    width: 100%;
    background: white;
  }
}
</style>
