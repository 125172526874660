export default [
  {
    icon: "mdi-view-dashboard-outline",
    label: "Base",
    popup: [
      {
        to: "/base/client",
        label: "Clients",
        icon: "mdi-account-details-outline",
        iconColor: "blue",
        description:
          "Aperçu de vos visiteurs et envoyez vos campagnes marketing.",
      },
      {
        to: "/guest-setup/branding",
        label: "WiFi Visiteurs",
        icon: "mdi-access-point-network",
        iconColor: "purple",
        description:
          "Personnalisez votre portail captif de connexion au Wi-Fi.",
      },
      {
        to: "/settings/integrations",
        label: "Integrations",
        icon: "mdi-share-variant-outline",
        iconColor: "green",
        description: "Connectez à Shoponyou vos logiciels tiers.",
      },
    ],
  },
  {
    icon: "mdi-finance",
    label: "Grow",
    popup: [
      {
        to: "/base/campaigns",
        label: "Campagne",
        icon: "mdi-message-fast-outline",
        iconColor: "orange",
        description: "Suivez et analysez de vos campagnes marketing.",
      },
    ],
  },
  {
    icon: "mdi-power",
    label: "Connect",
    popup: [
      {
        to: "/settings/wifi-settings",
        label: "Paramètres WiFi",
        icon: "mdi-wan",
        iconColor: "purple",
        description: "Configurez votre réseau wifi public et privé.",
      },
      {
        to: "/settings/wifi-analytics",
        label: "Marketing",
        icon: "mdi-shopping-outline",
        iconColor: "blue",
        description:
          "Analysez la fréquentation des visiteurs dans votre établissement.",
      },
    ],
  },
  {
    icon: "mdi-help-circle-outline",
    label: "Aide",
    popup: [
      {
        externalLink: "https://help.shoponyou.com/fr/",
        label: "Base de connaissance",
        icon: "mdi-database-outline",
        iconColor: "green",
        description:
          "Consultez nos articles d'aide à la configuration de votre portail.",
      },
      {
        externalLink:
          "https://shoponyou.pipedrive.com/scheduler/jOJPOzh7/formation-utilisateur-board-shoponyou",
        label: "Entraînement",
        icon: "mdi-school-outline",
        iconColor: "orange",
        description:
          "Réservez un créneau de formation avec un membre de notre équipe.",
      },
      {
        action: (userEmail) => {
          $crisp.push(["set", "user:email", [userEmail]]);
          $crisp.push(["do", "chat:open"]);
        },
        label: "Discutez avec nous",
        icon: "mdi-face-agent",
        iconColor: "pink",
        description: "Discutez avec notre support technique.",
      },
    ],
  },
];
