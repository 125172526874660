import { getAllUrl } from "@/core/js/utils/utils";
import axios from "axios";

const SET_PREV_PAGE = "SET_PREV_PAGE";
const SET_PAGE = "SET_PAGE";
const SET_NEXT_PAGE = "SET_NEXT_PAGE";

const SHOPPER_LIST_URL = "/app/keeper/api/data/shopper/list";
const SHOPPER_LIST_WITH_SIZE_URL = "/app/keeper/api/data/shopper/list?&size=10";
const COMPLETE_URL = "/app/keeper/api/data/shopper/complete";
const GENDER_URL = "/app/keeper/api/support/genders";
const AGE_RANGE_URL = "/app/keeper/api/support/age-ranges";
const SELECT_SHOPPER_URL = "/app/keeper/api/data/shopper/get/";
const SELECT_ALL_IDS_URL = "/app/keeper/api/data/shopper/id/list";

const navStore = {
  namespaced: true,
  state: {
    prevPage: null,
    page: null,
    nextPage: null,
  },
  mutations: {
    [SET_PREV_PAGE](state, value) {
      state.prevPage = value;
    },
    [SET_PAGE](state, value) {
      state.page = value;
    },
    [SET_NEXT_PAGE](state, value) {
      state.nextPage = value;
    },
  },
  actions: {
    fetchShoppers({ rootState }, page) {
      return axios({
        method: "get",
        url: getAllUrl(SHOPPER_LIST_WITH_SIZE_URL),
        headers: {
          Authorization: `Bearer ${rootState.userStore.token}`,
          "Content-Type": "application/json;charset=UTF-8",
        },
        params: {
          page: page,
        },
      })
        .then((response) => {
          return response.data;
        })
        .catch((err) => {
          throw err;
        });
    },
    fetchShopperList({ commit, dispatch }) {
      const current = dispatch("fetchShoppers", 0)
        .then((res) => res)
        .catch((err) => err);
      const next = dispatch("fetchShoppers", 1)
        .then((res) => res)
        .catch((err) => err);

      return Promise.all([current, next])
        .then((res) => {
          commit(SET_PAGE, res[0]);
          commit(SET_NEXT_PAGE, res[1]);
          return res[0];
        })
        .catch((err) => {
          throw err;
        });
    },
    fetchGender({ rootState }) {
      return axios({
        method: "get",
        url: getAllUrl(GENDER_URL),
        headers: {
          Authorization: `Bearer ${rootState.userStore.token}`,
          "Content-Type": "application/json;charset=UTF-8",
        },
      })
        .then((resp) => {
          return resp.data;
        })
        .catch((err) => {
          throw err;
        });
    },
    fetchAgeRange({ rootState }) {
      return axios({
        method: "get",
        url: getAllUrl(AGE_RANGE_URL),
        headers: {
          Authorization: `Bearer ${rootState.userStore.token}`,
          "Content-Type": "application/json;charset=UTF-8",
        },
      })
        .then((resp) => {
          return resp.data;
        })
        .catch((err) => {
          throw err;
        });
    },
    complete({ rootState }, param) {
      return axios({
        method: "get",
        url: getAllUrl(COMPLETE_URL),
        headers: {
          Authorization: `Bearer ${rootState.userStore.token}`,
          "Content-Type": "application/json;charset=UTF-8",
        },
        params: {
          startswith: param.startswith,
        },
      })
        .then((resp) => {
          return resp.data;
        })
        .catch((err) => {
          throw err;
        });
    },
    selectShopper({ rootState, commit }, param) {
      return axios({
        method: "get",
        url: getAllUrl(SELECT_SHOPPER_URL + param.ids),
        headers: {
          Authorization: `Bearer ${rootState.userStore.token}`,
          "Content-Type": "application/json;charset=UTF-8",
        },
      })
        .then((resp) => {
          commit(SET_PAGE, resp.data);
          return resp.data;
        })
        .catch((err) => {
          throw err;
        });
    },
    filterByRequest({ rootState }, params) {
      return axios({
        method: "get",
        url: getAllUrl(SHOPPER_LIST_URL),
        headers: {
          Authorization: `Bearer ${rootState.userStore.token}`,
          "Content-Type": "application/json;charset=UTF-8",
        },
        params: {
          age_range: params.age_range,
          last_visit: params.last_visit,
          total_visit: params.total_visit,
          visit_week: params.visit_week,
          visit_month: params.visit_month,
          gender: params.gender,
          sort: params.sort,
          page: params.page,
          size: params.size,
        },
      })
        .then((resp) => {
          return resp.data;
        })
        .catch((err) => {
          throw err;
        });
    },
    filterBy({ commit, dispatch }, params) {
      const previous = dispatch("filterByRequest", {
        ...params,
        page: params.page - 1,
      })
        .then((res) => res)
        .catch((err) => err);
      const current = dispatch("filterByRequest", params)
        .then((res) => res)
        .catch((err) => err);
      const next = dispatch("filterByRequest", {
        ...params,
        page: params.page + 1,
      })
        .then((res) => res)
        .catch((err) => err);

      return Promise.all([previous, current, next])
        .then((res) => {
          commit(SET_PREV_PAGE, res[0]);
          commit(SET_PAGE, res[1]);
          commit(SET_NEXT_PAGE, res[2]);
          return res[1];
        })
        .catch((err) => {
          throw err;
        });
    },
    getAllShoppersIds({ rootState }, params) {
      return axios({
        method: "get",
        url: getAllUrl(SELECT_ALL_IDS_URL),
        headers: {
          Authorization: `Bearer ${rootState.userStore.token}`,
          "Content-Type": "application/json;charset=UTF-8",
        },
        params: {
          age_range: params.age_range,
          last_visit: params.last_visit,
          total_visit: params.total_visit,
          visit_week: params.visit_week,
          visit_month: params.visit_month,
          gender: params.gender,
          sort: params.sort,
          page: params.page,
          size: params.size,
        },
      })
        .then((resp) => {
          return resp.data;
        })
        .catch((err) => {
          throw err;
        });
    },
  },
  getters: {
    prevPage: (state) => state.prevPage,
    page: (state) => state.page,
    nextPage: (state) => state.nextPage,
  },
};

export default navStore;
